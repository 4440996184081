import React from "react"

import { Box, Typography } from "@mui/material"

import Layout from "../components/layout"
import PhoneIcon from "../images/carbon_phone-filledphoneicon.svg"
import MailIcon from "../images/fluent_mail-28-filledemailicon.svg"
import OfficeIcon from "../images/heroicons-solid_office-buildingstreeticon.svg"
import ContactForm from "../components/ContactForm"
import FullWidthStripe from "../components/FullWidthStripe"

const Contact = () => {
  return (
    <Layout isContactPage>
      <FullWidthStripe
        bgcolor="#fafafa"
        py={{ xs: 4, lg: 10 }}
        px={{ xs: 2, sm: 5, lg: 10 }}
      >
        <Box
          width={1}
          maxWidth={{ xs: 1350, xl: "80vw" }}
          display="flex"
          flexWrap="wrap"
          alignItems={{ xs: "center", lg: "center" }}
          justifyContent={{ xs: "center", lg: "space-between" }}
          flexDirection={{ xs: "column", lg: "row" }}
          gap={{ xs: 2, md: 5 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            flex={{ lg: "1 500px" }}
            maxWidth={{
              xs: "100%",
              lg: 500,
              xl: 550,
            }}
          >
            <Typography
              variant="overline"
              color="primary"
              fontWeight={500}
              gutterBottom
            >
              Get in touch
            </Typography>
            <Typography variant="h2" sx={{ mb: 2.5 }} maxWidth={0.8}>
              We’d Love To Hear From You!
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 3, lg: 5 } }}
            >
              We would be happy to answer any of your questions regarding our
              work and our potential partnership! Please, fill out the form and
              we will get in touch with you shortly.
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row", lg: "column" }}
              flexWrap="wrap"
              gap={{ xs: 2, md: 3 }}
              sx={({ palette }) => ({
                "& > *": {
                  flex: { xs: 1, sm: "1 40%", lg: 1 },
                  alignItems: "center",
                },
                img: {
                  color: palette.text.secondary,
                  mr: 2,
                  width: { xs: 20, sm: 24, md: 28 },
                },
              })}
            >
              <Box display="flex">
                <img src={MailIcon} width={28} />
                <Typography variant="body1" color="text.tertiary">
                  info@infostudio.ba
                </Typography>
              </Box>
              <Box display="flex">
                <img src={PhoneIcon} />
                <Typography variant="body1" color="text.tertiary">
                  +387 (33) 267-680
                </Typography>
              </Box>
              <Box display="flex">
                <img src={OfficeIcon} />
                <Typography variant="body1" color="text.tertiary">
                  Mehmeda Spahe 26
                </Typography>
              </Box>
            </Box>
          </Box>

          <ContactForm postURI="contacts" />
        </Box>
      </FullWidthStripe>
    </Layout>
  )
}

export default Contact
